import { FC } from 'react'
import TitleText from '../TitleText/TitleText';
import styles from './Platform.module.scss';

const Platform: FC = () => {

    const elementsData = [
        {
            header: 'Employment law compliance',
            text: 'Staying on top of complex labor laws and regulations for your business.'
        },
        {
            header: 'Payroll compliance',
            text: 'Ensuring accurate and timely payment of employee wages and taxes.'
        },
        {
            header: 'Statutory compliances (ESI, PF, etc.)',
            text: 'Meeting your legal obligations and managing your employee welfare programs.'
        },
        {
            header: 'Employee benefits management',
            text: 'Ensuring compliance with all employee benefit regulations and requirements.'
        },
        {
            header: 'Contract drafting and review',
            text: 'Crafting and reviewing legal documents to protect your business interests.'
        },
        {
            header: 'Health and safety compliance',
            text: 'Keeping your workplace safe and healthy for your employees and customers.'
        },
    ]

    const renderListElements = () => {
        return elementsData.map((item, i) => (
            <li key={i}>
                <img src={`./images/platform/icons/${i + 1}.svg`} alt="Element Icon" />
                <h4>{item.header}</h4>
                <p>{item.text}</p>
            </li>
        ))
    }

    return (
        <section className={styles.platform}>
            <TitleText firstTitle="Experience a smarter" secondTitle="compliance and audit platform in your hand"/>
            <ul className={styles.platformWrapper}>
                {renderListElements()}
            </ul>
            <div className={styles.button}>
                <a href="/" className="action main">Talk to expert</a>
            </div>
        </section>
    )
}

export default Platform;