import { FC, useState } from 'react';
import styles from './Header.module.scss';

interface MultiplyItemProps {
    title: string;
    links: {
        link: string;
        title: string;
    }[];
}

const MultiplyItem: FC<MultiplyItemProps> = ({ title, links }) => {
    const [isMenuItemActive, setIsMenuItemActive] = useState(false);

    const clickOnMenuItem = () => {
        setIsMenuItemActive(prev => !prev);
    };

    const renderLinks = () => {
        return links.map((item, index) => (
            <li key={index}><a href={item.link}><span>{item.title}</span></a></li>
        ));
    };

    return (
        <li onClick={clickOnMenuItem}>
            <span className={isMenuItemActive ? styles.active: ''}>{title} <svg className={styles.arrow} width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.246.29a1 1 0 0 0-1.41 0l-3.59 3.54L1.706.29a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#425066"/></svg></span>
            <ul className={`${styles.submenu} ${isMenuItemActive ? styles.active: ''} ${styles.hoverUnderlineAnimation}`}>
                {renderLinks()}
            </ul>
        </li>
    );
};

export default MultiplyItem;