import { FC } from 'react'
import styles from './TitleText.module.scss';

interface TitleTextProps {
    firstTitle: string;
    secondTitle: string;
}

const TitleText: FC<TitleTextProps> = ({firstTitle, secondTitle}) => {
    return (
        <div className={styles.titleText}>
            <h3>{firstTitle}</h3>
            <h2>{secondTitle}</h2>
        </div>
    ) 
}

export default TitleText;