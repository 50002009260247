import { FC, useState } from 'react'
import styles from './Footer.module.scss';

interface FooterMenuItemProps {
    title: string;
    links: {
        link: string;
        title: string;
    }[]
}

const FooterMenuItem: FC<FooterMenuItemProps> = ({title, links}) => {

    const [activeMenu, setActiveMenu] = useState<boolean>(false);

    const onClickMobileMenuItem = () => {
        setActiveMenu(prev => !prev);
    }

    const renderLinks = () => {
        return links.map((item, index) => (
            <li key={index}><a href={item.link}>{item.title}</a></li>
        ))
    }
    
    return (
        <li className={`${activeMenu ? styles.active : ''}`}>
            <span className={styles.linkHeader} onClick={onClickMobileMenuItem}>{title} <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m13 6.58-5 5-5-5" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg></span>
            <ul className={`${styles.links} ${styles.hoverUnderlineAnimation}`}>
                {renderLinks()}
            </ul>
        </li>
    )
}

export default FooterMenuItem;