import { FC } from 'react'
import styles from './Privileges.module.scss';

const Privileges: FC = () => {
    return (
        <section className={styles.privileges}>
            <div className={styles.backgroundImage}>
                <h2>Why choose us</h2>
                <div className={styles.privilegesWrapper}>
                    <ul className={styles.privilegesTextLeft}>
                        <li>
                            <h4>Economic</h4>
                            <p>Affordable solutions for all your needs</p>
                        </li>
                        <li>
                            <h4>Professional</h4>
                            <p>Experienced professionals at your service</p>
                        </li>
                        <li>
                            <h4>Security</h4>
                            <p>Your data is secure with us</p>
                        </li>
                    </ul>
                    <div className={styles.mainImage}>
                        <img className={styles.desktop} src='./images/privileges/main-desktop.png' alt="Priviliges" loading='lazy'/>
                        <img className={styles.mobile} src='./images/privileges/main-mobile.png' alt="Priviliges" loading='lazy'/>
                    </div>
                    
                    <ul className={styles.privilegesTextRight}>
                        <li>
                            <h4>Time</h4>
                            <p>Streamlined processes for faster results</p>
                        </li>
                        <li>
                            <h4>24*7</h4>
                            <p>Always available to support you</p>
                        </li>
                        <li>
                            <h4>User satisfaction</h4>
                            <p>Your satisfaction is our top priority.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Privileges;